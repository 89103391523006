<template>
  <div >
    <el-row>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleStationAdd">
        {{$t('Addstation')}}
      </el-button>
      <!--<el-divider content-position="right"> {{$t('FilterConditions')}} </el-divider>-->
      <!--篩選區塊-->
      <div style="float: right;">
          <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
              <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
          </el-select>
          <el-select v-model="listQuery.enabled" size="small" class="filter-item">
            <el-option v-bind:label="$t('EnableStatus')" :value="true" />
            <el-option v-bind:label="$t('Disable')+$t('Status')" :value="false" />
          </el-select>
          <el-select v-model="listQuery.externalService" size="small" class="filter-item">
            <el-option v-bind:label="$t('ExternalServices')+':'+$t('Yes')" :value="true" />
            <el-option v-bind:label="$t('ExternalServices')+':'+$t('No')" :value="false" />
          </el-select>
          <el-select v-model="listQuery.enabledOcpi" size="small" class="filter-item">
            <el-option v-bind:label="'OCPI'+$t('Enable')" :value="true" />
            <el-option v-bind:label="'OCPI'+$t('NotEnabled')" :value="false" />
          </el-select>
          <el-input size="small" v-model="listQuery.name" v-bind:placeholder="$t('Name')" class="filter-item" clearable />
      </div>
    </el-row>
    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
      @row-click="handleRowClick"
     >
      <!--<el-table-column align="center" prop="id" label="編號" sortable />-->
      <el-table-column align="center" prop="name" v-bind:label="$t('Name')" sortable />
      <el-table-column align="center" prop="service.name" :label="$t('Operator')" v-if="serviceList.length>0" />
      <el-table-column align="center" prop="code" v-bind:label="$t('Code')" sortable />
      <!--<el-table-column align="center" prop="serviceId" v-bind:label="$t('Operator')" sortable />-->
      <el-table-column align="center" prop="address" v-bind:label="$t('Location')" min-width="150%" />
      <el-table-column align="center" prop="enabled" v-bind:label="$t('EnableStatus')" >
        <template slot-scope="scope">
            <el-tag v-if="scope.row.enabled" type="danger"> {{$t('Enable')}} </el-tag>
            <el-tag v-else type="success"> {{$t('Disable')}} </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="externalService" v-bind:label="$t('ExternalServices')" >
        <template slot-scope="scope">
            <el-tag v-if="scope.row.externalService" type="danger"> {{$t('Yes')}} </el-tag>
            <el-tag v-else type="success"> {{$t('No')}} </el-tag>
        </template>
      </el-table-column>
      <el-table-column  align="center" prop="createTime" v-bind:label="$t('Create')+$t('Date')" :show-overflow-tooltip="true" >
        <template slot-scope="scope">
          {{ parseDateTime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center"  v-bind:label="$t('Business')"  >
        <template slot-scope="scope">
           <el-button type="primary" size="mini" @click="showBusinessData(scope.row)" ><svg-icon iconName="view-details" /></el-button>  <!--需增加另外一頁-->
        </template>
      </el-table-column>

      <el-table-column align="center" :label="$t('Operate')" width="130px" >
        <template slot-scope="scope">
           <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showStationSetting(scope.row)"/>
           <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" v-if="!scope.row.enabledOCPI" @click="handleStationDelete(scope.$index, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <StationSetting :dialogStationVisible="dialogStationSettingVisible" :stationData="stationData" :serviceList="serviceList" @close="dialogStationSettingOnClose()" />
    <StationBussinessSetting :dialogBusinessVisible="dialogBusinessSettingVisible" :businessData="businessData" :stationData="stationData" @close="dialogStationBusinessOnClose()"/>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import StationSetting from './StationSetting.vue'
import StationBussinessSetting from './StationBussinessSetting.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";

export default {
  name: 'Station',
  components: {
    Pagination,
    StationSetting,
    StationBussinessSetting,
  },
  mixins: [mixins],
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      serviceList: [],
      listLoading: false,
      listQuery: {
        serviceId: undefined,
        enabled: true,
        externalService: true,
        enabledOcpi: false,
        name: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      stationData: undefined, // 充電站資訊
      businessData: [],  // 營業資料帶入
      dialogStationSettingVisible: false,
      dialogBusinessSettingVisible: false, //營業資料dialog 控制是否開啟
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.serviceId': function () {
        this.listQuery.page = 0
        this.resetPage(this.getList)
    },
    'listQuery.enabled': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.externalService': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.enabledOcpi': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.name': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
  },
  created() {
    if(this.$route.params.serviceId != undefined)
        this.listQuery.serviceId = this.$route.params.serviceId
    this.dialogWidth = this.setDialogWidth()
    this.resetPage(this.getList)
    this.getServiceOptions();
    //refreshToken();
  },
  beforeDestroy() {
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/station', {params: this.listQuery}).then(res=> {
        this.list = res.data.content
        this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data
        })
    },
    getBussinessData(val1,val2)
    {
      this.axios.get('/api/v1/station_business/query/business_time_list?serviceId='+val1  + '&stationId='+val2).then(
        res => {
          this.businessData=res.data.data;
          }
        );

    },
    handleRowClick(row, column, event) {
    },
    handleStationAdd() {
      this.dialogStationSettingVisible = true
    },
    showStationSetting(row) {
      this.dialogStationSettingVisible = true
      this.stationData = row
    },
    //bowa add 09/09
    showBusinessData(row){
      //先取得營業時間等資料更新在營業資訊攔位
      this.getBussinessData(row.service.id, row.id)
      this.dialogBusinessSettingVisible = true
      this.stationData = row
 
    },
    handleStationDelete(index, row) {
      const confirmText = ['確定刪除充電站 - ' + row.name + ' ?','(請確認此充電站已無納管任何充電椿)']
      const newDatas = []
      const h = this.$createElement
      for (const i in confirmText) {
        newDatas.push(h('p', null, confirmText[i]))
      }
      this.$confirm('提示', {
        message: h('div', null, newDatas),
        //message: '確定刪除充電站 - ' + row.name + ' ?',
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delStation(index, row.id)
      }).catch(()=> {
        console.log('cancel delete station')
      })
    },
    async delStation(index, id) {
      this.axios.delete('/api/v1/station/' + id).then(()=> {
        this.$store.dispatch("getStationByCurrentAccount") // 更新所有充電站資訊
        this.$delete(this.list, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗 ('+err.data+')',type: 'error'})
      })
    },
    dialogStationSettingOnClose() {
      this.dialogStationSettingVisible = false
      this.stationData = undefined
      this.resetPage(this.getList)
    },
    dialogStationBusinessOnClose(){   //營業資料dialog 關閉
    this.dialogBusinessSettingVisible = false
    },
  }
}
</script>
<style lang="scss" scoped>
// 設定 hover css效果
.el-table .cell:hover{
  .title{
    color: 	#004B97;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
